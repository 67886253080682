<template>
  <div class="button-grid">
    <Button @click="onSave" :label="$t('save')" class="p-button-success " icon="pi pi-check" />

    <ConfirmButton
      colorVariant="success"
      :disabled="emailButtonDisabled()"
      :callbackId="this.appointmentCapacity.appointment.id"
      :confirmCallback="onSendAppointmentConfirmationMail"
      class=" p-button-secondary"
    >
      <template v-slot:buttonText>
        <span class="pi pi-envelope p-button-icon p-button-icon-left"></span>
        <span class="p-button-label ml-2">Termin bestätigen</span>
      </template>
      <template v-slot:header> {{ $t('confirm_appointment') }}? </template>
      <template v-slot:confirmButtonText>
        {{ $t('confirm_appointment') }}
      </template>
      <template v-slot:content>
        E-Mail-Bestätigung wird versendet an:
        <strong
          v-if="
            appointmentCapacity.appointment.project &&
              appointmentCapacity.appointment.project.customer.email
          "
        >
          {{ appointmentCapacity.appointment.project.customer.email }}</strong
        >
      </template>
    </ConfirmButton>

    <Button
      @click="onCancel"
      :label="$t('cancel')"
      class="p-button-secondary "
      icon="pi pi-times"
    />

    <ConfirmDeleteButton class="p-button-secondary " v-if="canDelete" :confirmCallback="onDelete">
      <template #buttonText> {{ $t('slot') }} {{ $t('delete') }} </template>
    </ConfirmDeleteButton>
  </div>
</template>

<script>
import Button from 'primevue/button';
import ConfirmButton from '@/components/ConfirmButton.vue';
import ConfirmDeleteButton from '@/components/ConfirmDeleteButton.vue';

export default {
  props: [
    'appointmentCapacity',
    'canDelete',
    'onSave',
    'onCancel',
    'onDelete',
    'emailButtonDisabled',
    'onSendAppointmentConfirmationMail',
  ],
  components: {
    Button,
    ConfirmButton,
    ConfirmDeleteButton,
  },
};
</script>

<style scoped lang="scss">
.button-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5em;
}
</style>
