<template>
  <FullCalendar
    id="calendar"
    v-if="appointmentCapacities"
    :options="calendarOptions"
    ref="fullCalendar"
    v-on:appointment-capacity-edit:close="onCloseEdit"
  >
    <template #eventContent="arg">
      <b-badge :variant="getAppointmentTypeColor(arg.event.extendedProps.appointmentType)">
        {{ $t(arg.event.extendedProps.appointmentType) }}
      </b-badge>
      <b-badge v-if="arg.event.extendedProps.zipArea">
        PLZ&nbsp;
        {{ arg.event.extendedProps.zipArea }}
      </b-badge>
      <b-badge variant="primary" v-if="hasEmployees(arg.event.extendedProps)">
        {{ employeeShortcodes(arg.event.extendedProps) }}
      </b-badge>
      <div v-if="arg.event.extendedProps.appointment" class="pt-1 pl-1">
        {{ $time(arg.event.extendedProps.appointment.installationStartAt) }} -
        {{ $time(arg.event.extendedProps.appointment.installationEndAt) }}
      </div>
      <div
        v-if="arg.event.extendedProps.appointment && arg.event.extendedProps.appointment.project"
        class="pl-1"
      >
        {{ arg.event.extendedProps.appointment.project.customer.lastname }},
        {{ arg.event.extendedProps.appointment.project.customer.firstname }}
      </div>
      <div v-if="!arg.event.extendedProps.appointment" class="pl-1 pt-1"><b>Frei</b></div>
    </template>
  </FullCalendar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import de from '@fullcalendar/core/locales/de';
import { getAppointmentTypeColor } from '@/helpers/colors';

export default {
  components: {
    FullCalendar,
  },
  props: {
    selectEventCallback: Function,
    dateChangedCallback: Function,
  },
  data() {
    return {
      startDate: null,
      endDate: null,

      calendarOptions: {
        displayEventTime: false,
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
        },
        locale: de,
        plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
        initialView: 'dayGridWeek',
        selectable: true,
        events: this.appointmentCapacities,
        eventClick: this.handleEventClick,
        eventOrder: 'prio',
        customButtons: {
          customPrev: {
            text: 'Prev',
            click: this.handlePrevClick,
          },
          customNext: {
            text: 'Next',
            click: this.handleNextClick,
          },
          customToday: {
            text: 'Heute',
            click: this.handleTodayClick,
          },
          customDayGridWeek: {
            text: 'Woche',
            click: this.handleDayGridWeekClick,
          },
          customDayGridMonth: {
            text: 'Monat',
            click: this.handleDayGridMonthClick,
          },
        },
        headerToolbar: {
          left: 'customPrev,customNext,customToday',
          center: 'title',
          right: 'customDayGridWeek,customDayGridMonth',
        },
        buttonIcons: {
          customNext: 'chevron-right',
          customPrev: 'chevron-left',
        },
      },
    };
  },
  computed: {
    ...mapGetters(['getAppointmentCapacities']),
    appointmentCapacities() {
      return this.getAppointmentCapacities;
    },
  },
  methods: {
    getAppointmentTypeColor,
    hasEmployees(appointmentCapacity) {
      if (appointmentCapacity.appointment && appointmentCapacity.appointment.employees.length > 0) {
        return true;
      }

      return appointmentCapacity.employees.length > 0;
    },

    /**
     * Generate a string with the initials of the employees that are assigned to a apointmentCapacity/appointment
     * If an appointment is present in the apointmentCapacity use the employees from the appointment, else use the employees from the apointmentCapacity
     */
    employeeShortcodes(appointmentCapacity) {
      const employeeList = appointmentCapacity.appointment
        ? appointmentCapacity.appointment.employees
        : appointmentCapacity.employees;
      let employeeString = '';
      employeeString = employeeList.reduce((acc, employee, index) => {
        const space = this.isFirstCharDigit(employee.firstname) ? ' ' : '';
        const initials =
          this.nameToInitial(employee.firstname) + space + this.nameToInitial(employee.lastname);

        if (index === 0) {
          return initials ? acc + initials : acc; // Skip if both first and last names are null or undefined
        } else {
          return initials ? acc + ', ' + initials : acc; // Skip if both first and last names are null or undefined
        }
      }, employeeString);

      return employeeString;
    },

    /**
     * Format a name to a single character or if numeric return as is
     * @param {*} name
     */
    nameToInitial(name) {
      if (!name) return '';
      if (this.isFirstCharDigit(name)) return name;
      return name.charAt(0);
    },

    isFirstCharDigit(str) {
      return /^[0-9]/.test(str);
    },

    handleEventClick: function(data) {
      if (this.selectEventCallback) {
        this.selectEventCallback(data.event.id);
      }
    },

    handlePrevClick() {
      this.$refs.fullCalendar.getApi().prev();
      this.updateDateInfo();
    },

    handleNextClick() {
      this.$refs.fullCalendar.getApi().next();
      this.updateDateInfo();
    },

    handleTodayClick() {
      this.$refs.fullCalendar.getApi().today();
      this.updateDateInfo();
    },

    handledayGridWeekClick() {
      this.$refs.fullCalendar.getApi().changeView('dayGridWeek');
      this.updateDateInfo();
    },

    handleDayGridWeekClick() {
      this.$refs.fullCalendar.getApi().changeView('dayGridWeek');
      this.updateDateInfo();
    },

    handleDayGridMonthClick() {
      this.$refs.fullCalendar.getApi().changeView('dayGridMonth');
      this.updateDateInfo();
    },

    updateDateInfo() {
      this.getCurrentDateRange();
      this.dateChangedCallback({ startDate: this.startDate, endDate: this.endDate });
    },

    onCloseEdit: function() {
      // console.log('onCloseEdit');
    },

    getCurrentDateRange() {
      if (this.$refs.fullCalendar) {
        const calendarApi = this.$refs.fullCalendar.getApi();
        const view = calendarApi.view;
        this.startDate = view.activeStart;
        this.endDate = view.activeEnd;
        // console.log('Current Date Range:', this.startDate, this.endDate);
        // Use startDate and endDate for further processing
      } else {
        console.warn('Calendar instance not yet available');
      }
    },
  },

  async created() {
    this.$eventHub.$on('appointment-capacity-edit:close', (name) => {
      // console.log('appointment-capacity-edit:close', name);

      if (this.$refs.fullCalendar) {
        const calendarApi = this.$refs.fullCalendar.getApi();
        calendarApi.render();
      }
    });
  },

  async mounted() {
    this.calendarOptions.events = this.appointmentCapacities;
  },

  watch: {
    appointmentCapacities(newValue, oldValue) {
      this.calendarOptions.events = newValue;
    },
  },
};
</script>

<style scoped lang="scss">
#calendar {
  width: 100%;
  margin: 0 auto;
}

::v-deep .fc-daygrid-event-harness {
  margin: 0.4em 0.1em;
}
::v-deep .fc-event-main {
  min-height: 62px;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
}
::v-deep .pl-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .badge {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .badge-primary {
  width: 79%;
}
::v-deep .badge-appointmentTypeA {
  width: 20%;
}
::v-deep .badge-appointmentTypeB {
  width: 20%;
}
::v-deep .badge-appointmentTypeC {
  width: 20%;
}
@media (max-width: 800px) {
  ::v-deep .badge-primary {
    width: 62%;
  }

  ::v-deep .badge-appointmentTypeA {
    width: 37%;
  }
  ::v-deep .badge-appointmentTypeB {
    width: 37%;
  }
  ::v-deep .badge-appointmentTypeC {
    width: 37%;
  }
}
</style>
